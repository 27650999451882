
import {
  menuController,
  IonContent,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonHeader,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { vueWindowSizeMixin } from "vue-window-size/option-api";
import { getHttp, postHttp, patchHttp, deleteHttp } from "@/http/fetchApi";
import { useStore} from "@/store";


export default defineComponent({
  name: "App",
  components: {
    IonToolbar,
    IonMenuButton,
    IonButtons,
  },
  mixins: [vueWindowSizeMixin()],
  data() {
    return {
      name: "Ticket & Angebote",
      emp: { name: "Ram", age: 25 },
    };
  },
  props:['openMenu'],

  setup() {
    const store = useStore();
    const menuTranslation = ref({
      de: "Menü",
      en: "Menu",
      da: "Menu",
      cs: "nenu",
      it: "Menu",
      fr: "Menu",
      es: "Menú",
      pl: "Menu",
      nl: "Menu",
    });

    const openFirst = () => {
      menuController.open();
    };

    

    //const path = window.location.pathname.split("folder/")[1];
   

    //const route = useRoute();

    return {
      menuTranslation,
      store,
      openFirst,
      // isSelected: (url: string) => url === route.path ? 'selected' : ''
    };
  },
});
