
import {
  IonPage,
  IonApp,
  IonToolbar,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonButtons,
  IonHeader,
  IonMenuButton,
  menuController,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import { createAnimation } from "@ionic/vue";
import { useRoute } from "vue-router";
import { warningSharp } from "ionicons/icons";
import NavigationBar from "@/template/navigationBar.vue";
import CarouselDb from "@/template/CarouselDb.vue";
import PictureTable from "@/template/PictureTable.vue";

import TemplateImpressum from "@/template/Impressum.vue";
import TemplateHeaderMenu from "@/template/HeaderMenu.vue";
import TemplateRespMenu from "@/template/RespMenu.vue";
import { useStore, MUTATIONS } from "@/store";
import { getHttp, postHttp, patchHttp, deleteHttp } from "@/http/fetchApi";
import { useWindowSize } from 'vue-window-size';
export default defineComponent({
  name: "App",
  components: {
    NavigationBar,
    TemplateImpressum,
    TemplateHeaderMenu,
    TemplateRespMenu,
    CarouselDb,
    PictureTable,
    IonApp,
    // IonRouterOutlet,
    IonPage,
    IonContent,
    IonMenu,
    IonToolbar,
    /*
    IonItem,
    IonList,
    IonLabel,
    IonListHeader,
  */
  },

 setup() {
   const { width, height } = useWindowSize();
   
    

console.log("Width: " + width.value);
    console.log("App Page");
    const showLanguage = ref(false);
    //const getMenuData = () => {
      const setShowLanguage = (showValue: boolean) => {
        showLanguage.value = showValue;
      };
    const myElementRef = ref();
    const hideEl = ref({ hideEl: true });

    const menuRef = ref();
    const store = useStore();
    store.commit(MUTATIONS.initialiseStore);

    const menuDataLevel = ref(0);
    const menuDataIndex = ref(0);

  

    const openFirst = () => {
      console.log("open");
      //menuController.enable(true, "first");
      //menuController.open("first");
      //menuRef.value.$el.open(false);
    };

    //const headerDataSetup = ref({});
    const mobilData = ref({
      main: {
        title: "",
        link: "",
        level: "",
        children: {
          title: "",
          link: "",
          level: "",
          children: [{ title: "", link: "", level: "", children: [] }],
        },
      },
      meta: [{ title: "", link: "", info: "" }],
      language: { label: "" },
    });

    const mobilDataCalc = ref();
    const menuIndex = ref([] as  any);

      const closeMenu = () => {
      //console.log(menuRef.value);
      // menuRef.value.$el.close(false);
      menuIndex.value = [];
      retrieveMenuKnot();
      showLanguage.value = false;
      menuController.close();
    };

    const getMenuData = () => {
      getHttp(
        "https://db-bhfs.db-app.de/menu",
        {
          screen: "mobile",
          lang: store.state.lang,
        },
        ""
      ).then((res) => {
        mobilData.value = res.data;
        menuIndex.value = [];
        retrieveMenuKnot();
      });
    };

    getMenuData();
    


    // !Array.isArray(menuIndex.value) || !menuIndex.value.length
    const retrieveMenuKnot = () => {

      if (menuIndex.value.length == 0) {

        mobilDataCalc.value = mobilData.value.main.children;  
        delete mobilDataCalc.value[4];
        delete mobilDataCalc.value[3];
        mobilDataCalc.value = mobilDataCalc.value.filter(x => x !== null);
      } else if (menuIndex.value.length == 1) {
         mobilDataCalc.value = mobilData.value.main.children[menuIndex.value[0]].children;
        console.log("1");
      } else if (menuIndex.value.length == 2) {
        mobilDataCalc.value = mobilData.value.main.children[menuIndex.value[0]].children[menuIndex.value[1]].children;
        console.log("2");
      } else if (menuIndex.value.length == 3) {
        mobilDataCalc.value = mobilData.value.main.children[menuIndex.value[0]].children[menuIndex.value[1]].children[menuIndex.value[2]].children;
        console.log("3");
      } else if (menuIndex.value.length == 4) {
        mobilDataCalc.value = mobilData.value.main.children[menuIndex.value[0]].children[menuIndex.value[1]].children[menuIndex.value[2]].children[menuIndex.value[3]].children;
        console.log("4");
      } else if (menuIndex.value.length == 5) {
        mobilDataCalc.value = mobilData.value.main.children[menuIndex.value[0]].children[menuIndex.value[1]].children[menuIndex.value[2]].children[menuIndex.value[3]].children[menuIndex.value[4]].children;
        console.log("4");
      } else{
        //Fehler
        console.log("retrieveMenuKnot letzter else Zweig");
        menuIndex.value = [];
        mobilDataCalc.value = mobilData.value.main.children;
      }
      
    };

  
    const setLangValue = async (langValue: string) => {
      
      store.commit(MUTATIONS.EDIT_LANG, langValue);

      getHttp(
        "https://db-bhfs.db-app.de/menu",
        {
          screen: "mobile",
          lang: store.state.lang,
        },
        ""
      ).then((res) => {
        mobilData.value = res.data;
        menuIndex.value = [];
        retrieveMenuKnot();
        closeMenu();
      });
    };

    const languagesData = {
      cs: {
        title: "Český",
        key: "cs",
        link: "https://www.bahn.com/cs",
        newTab: false,
      },
      da: {
        title: "Dansk",
        key: "da",
        link: "https://www.bahn.com/da",
        newTab: false,
      },
      en: {
        title: "English",
        key: "en",
        link: "https://www.bahn.com/en",
        newTab: false,
      },
      es: {
        title: "Español",
        key: "es",
        link: "https://www.bahn.com/es",
        newTab: false,
      },
      fr: {
        title: "Français",
        key: "fr",
        link: "https://www.bahn.com/fr",
        newTab: false,
      },
      it: {
        title: "Italiano",
        key: "it",
        link: "https://www.bahn.com/it",
        newTab: false,
      },
      nl: {
        title: "Nederlands",
        key: "nl",
        link: "https://www.bahn.com/nl",
        newTab: false,
      },
      pl: {
        title: "Polski",
        key: "pl",
        link: "https://www.bahn.com/pl",
        newTab: false,
      },
    };

    const route = useRoute();

    const menuTranslation = ref({
      de: "Menü",
      en: "Menu",
      da: "Menu",
      cs: "nenu",
      it: "Menu",
      fr: "Menu",
      es: "Menú",
      pl: "Menu",
      nl: "Menu",
    });
        const headerTranslation = {de:{startseite: {title: "Startseite", link: "https://www.bahn.de/"},
     bahnDe: {title: "bahn.de/aktuell", link: "https://www.bahn.de/service/fahrplaene/aktuell"}, 
     hilfeKontakt: {title:"Hilfe & Kontakt", link:"https://www.bahn.de/service/hilfe"}, suchen: "Suchen", actionLink: "https://www.bahn.de/suche", hiddenS: "bahn-de", hiddenL: "de"},
en:{startseite: {title: "Home", link: "https://www.bahn.com/en"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/en/contact"}, suchen: "Search", actionLink: "https://www.bahn.com/en/suche", hiddenS: "bahn-com", hiddenL: "en"},
da:{startseite: {title: "Startside", link: "https://www.bahn.com/da"},
 bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Kontakt", link:"https://www.bahn.com/da/kontakt"}, suchen: "Søg", actionLink: "https://www.bahn.com/da/suche", hiddenS: "bahn-com", hiddenL: "da"},
cs:{startseite: {title: "Úvod", link: "https://www.bahn.com/cs"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Hilfe & Kontakt", link:"https://www.bahn.com/cs/kontakt"}, suchen: "najít", actionLink: "https://www.bahn.com/cs/suche", hiddenS: "bahn-com", hiddenL: "cs"},
it:{startseite: {title: "Startseite", link: "https://www.bahn.com/it"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contatti", link:"https://www.bahn.com/it/contatti"}, suchen: "Cerca", actionLink: "https://www.bahn.com/it/suche", hiddenS: "bahn-com", hiddenL: "it"},
fr:{startseite: {title: "Page d´accueil", link: "https://www.bahn.com/fr"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/fr/contact"}, suchen: "Rechercher", actionLink: "https://www.bahn.com/fr/suche", hiddenS: "bahn-com", hiddenL: "fr"},
es:{startseite: {title: "Home", link: "https://www.bahn.com/es"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contacto", link:"https://www.bahn.com/es/contacto"}, suchen: "Buscar", actionLink: "https://www.bahn.com/es/suche", hiddenS: "bahn-com", hiddenL: "es"},
pl:{startseite: {title: "Strona główna", link: "https://www.bahn.com/pl"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Kontakt", link:"https://www.bahn.com/pl/kontakt"}, suchen: "Szukaj", actionLink: "https://www.bahn.com/pl/suche", hiddenS: "bahn-com", hiddenL: "pl"},
nl:{startseite: {title: "Home", link: "https://www.bahn.com/nl"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/nl/contact"}, suchen: "Zoeken", actionLink: "https://www.bahn.com/nl/suche", hiddenS: "bahn-com", hiddenL: "nl"}}
   
   watch(width, (currentValue, oldValue) => {
      if(currentValue > 990){
        menuRef.value.$el.close(false);
      }
    });
   
   return {
      openFirst,
      closeMenu,
      store,
      menuRef,
      mobilData,
      retrieveMenuKnot,
      menuIndex,
      mobilDataCalc,
      setLangValue,
      menuDataIndex,
      menuDataLevel,
      myElementRef,
      menuTranslation,
      showLanguage, 
      setShowLanguage, headerTranslation, windowWidth: width,
      windowHeight: height,
      // isSelected: (url: string) => url === route.path ? 'selected' : ''
    };
  },
});
