import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Content",
    //redirect: '/folder/Inbox'

    component: () => import("../views/Startseite.vue"),
  },
  {
    path: "/content",
    //redirect: '/folder/Inbox'
    component: () => import("../views/Content.vue"),
  },
  {
    path: "/profil",
    //redirect: '/folder/Inbox'
    component: () => import("../views/Profil.vue"),
  },
  {
    path: "/profil/index.html",
    //redirect: '/folder/Inbox'
    component: () => import("../views/Profil.vue"),
  },
  {
    path: "/fahrteneintragen",
    //redirect: '/folder/Inbox'
    component: () => import("../views/FahrtenEintragen.vue"),
  },
  {
    path: "/reservieren",
    name: "Reservieren",
    // redirect: '/reservieren',

    component: () => import("../views/Reservieren.vue"),
  },
  {
    path: "/meinefahrten",
    //redirect: '/folder/Inbox'
    component: () => import("../views/MeineFahrten.vue"),
  },

  {
    path: "/dashboard",
    //redirect: '/folder/Inbox'
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/picturetable",
    //redirect: '/folder/Inbox'
    component: () => import("../template/PictureTable.vue"),
  },
  {
    path: "/carousel",
    //redirect: '/folder/Inbox'
    component: () => import("../template/CarouselDb.vue"),
  },

  /*
  {
    path: "/profil",
    name: "Menu",
    component: Menu,
  }, //HeaderMenu.vue
  /*
  {
    path: '/home',
    //redirect: '/folder/Inbox'
    component: () => import ('../views/Home.vue')
  }, //HeaderMenu.vue
  {
    path: '/menuHeader',
    //redirect: '/folder/Inbox'
    component: () => import ('../template/HeaderMenu.vue')
  }, //HeaderMenu.vue
 
  {
    path: '/folder',
    //path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/item',
    //redirect: '/item',
    component: () => import ('../views/Item.vue')
  },
  {
    path: '/PopOver',
    //redirect: '/item',
    component: () => import ('../views/PopOverExample.vue')
  },
*/
  //
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
