
import {
  IonText,
  IonSlide,
  IonSlides,
  IonImg
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
  },
  setup() {
    const sliderData = {
      sl: [
        {
          slLink: "http://www.glasgow-commitment.de",
          imgSrc: "assets/carousel/DB_Glasgow_Commitment_Logo_4zu2.jpg",
          heading: "Das Glasgow Commitment",
          text: "Gestalten Sie mit uns innerdeutsche Geschäftsreisen klimafreundlicher.",
          textLink: "Jetzt teilnehmen und profitieren",
          textLinkExtern: true,
        },       
        {
          slLink: "https://www.bahn.de/service/mobile/db-navigator",
          imgSrc: "assets/carousel/Navi4_ohne_DB.jpg",
          heading: "DB Navigator: So reist man heute",
          text: "Reisen Sie papierlos mit unseren digitalen Tickets.",
          textLink: "Jetzt informieren",
          textLinkExtern: false,
        },
        {
          slLink: "https://www.bahn.de/angebot/urlaub/hotel",
          imgSrc: "assets/carousel/HRS-BB-App-1125x563px_button.jpg",
          heading: "Hotel buchen und Vorteile sichern",
          text: "Bei jeder Hotelbuchung sichern Sie sich jetzt 3-fach BahnBonus Punkte.",
          textLink: "Jetzt Hotel finden",
          textLinkExtern: false,
        },
        {
          slLink: "https://www.zusammengegencorona.de/impfen/hier-wird-geimpft",
          imgSrc: "assets/carousel/DB_HWG_Header_3_4zu2.jpg",
          heading: "Jetzt impfen lassen!",
          text: "Finden Sie Termine in Ihrer Nähe.",
          textLink: "Zusammen gegen Corona",
          textLinkExtern: true,
        },
      ],
    };

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    };


    if(sliderData.sl.length < 2){
      slideOpts.pagination.type = "";
    }

    const ionSlider = ref();
    const nextSlide = () => {
      ionSlider.value.$el.isEnd().then((i) => {
        if (i) {
          ionSlider.value.$el.slideTo(0, 400);
        } else {
          ionSlider.value.$el.slideNext();
        }
      });
    };
    const prevSlide = () => {
      ionSlider.value.$el.isBeginning().then((i) => {
        if (i) {
          ionSlider.value.$el.length().then((l) => {
            ionSlider.value.$el.slideTo(l - 1, 400);
          });
        } else {
          ionSlider.value.$el.slidePrev();
        }
      });
    };

 // ionSlider.value.$el.update().then( () => {console.log("")} );

    return {
      sliderData,
      slideOpts,
      nextSlide,
      prevSlide,
      ionSlider,
      
    };
  },
});
