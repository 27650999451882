
import { defineComponent, ref } from "vue";
import { getHttp, postHttp, patchHttp, deleteHttp } from "@/http/fetchApi";
import { useStore,  MUTATIONS } from '../store';
import {IonToolbar} from "@ionic/vue";

export default defineComponent({
  name: "Impressum",
  components: {
    IonToolbar,
  },
  setup(){

     const store = useStore();

      const  impressumData = ref(
        {"desktop":{"de":[]}}
      );

    getHttp(`https://db-bhfs.db-app.de/footer`,"","")
    .then((res) => {
     impressumData.value = res.data;
    });

  return {
    store, 
    impressumData
  };

  }

/*
  data() {

   

    return {
      impressumData: {"desktop":{"de":[]}},
    };
  },
  methods: {
    getImpressum: async function () {   
 
    getHttp(`https://db-bhfs.db-app.de/footer`,"","")
    .then((res) => {
     this.impressumData = res.data;
    });

       
    },
  },
  created() {
    this.getImpressum();
  },


  */
});
