import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, MutationTree, ActionTree, } from 'vuex';

export type State = { lang: any};

export const key: InjectionKey<Store<State>> = Symbol();

const state: State = {
lang: "de", 
};

export const enum MUTATIONS {
  EDIT_LANG = 'EDIT_LANG',
  initialiseStore = 'initialiseStore'
}

const mutations: MutationTree<State> = {
  
  [MUTATIONS.EDIT_LANG](state, lang: "") {

    state.lang = lang;
  },
  [MUTATIONS.initialiseStore](state) {

    // Check if the ID exists
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store') || '{}'))
				);
			}
  },


};



export const store = createStore<State>({ state, mutations });

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});

// our own useStore function for types
export function useStore() {
  return baseUseStore(key);
}
