
import { IonToolbar, IonButtons, IonMenuButton, IonSelect, IonSelectOption,IonLabel, IonInput, IonPopover, } from "@ionic/vue";
import { defineComponent, ref, watchEffect } from "vue";
import { vueWindowSizeMixin } from "vue-window-size/option-api";
import { getHttp, postHttp, patchHttp, deleteHttp } from "@/http/fetchApi";
import { useRoute } from "vue-router";
import { useStore,  MUTATIONS } from '../store';

export default defineComponent({
  name: "HeaderMenu",
  components: {
    IonToolbar,
    IonPopover,
    IonInput
  },
  mixins: [vueWindowSizeMixin()],

   setup() {

     /*
     const focusSearch = () => {
       const elem1: any = document.querySelector("#search-input");
        elem1!.style.border = "solid 1px #878c96";
        console.log("focusSearch");

   // document.getElementById('email-input').style.border = "solid 2px #107CF1";
  }
  */

    const langValues = {cs: "Český", da: "Dansk", de: "Deutsch", en: "English", es: "Español", fr: "Français", it: "Italiano", nl: "Nederlands", pl: "Polski"}


    const lang = ref("de");
    const store = useStore();

    const headerTranslation = {de:{startseite: {title: "Startseite", link: "https://www.bahn.de/"},
     bahnDe: {title: "bahn.de/aktuell", link: "https://www.bahn.de/service/fahrplaene/aktuell"}, 
     hilfeKontakt: {title:"Hilfe & Kontakt", link:"https://www.bahn.de/service/hilfe"}, suchen: "Suchen", actionLink: "https://www.bahn.de/suche", hiddenS: "bahn-de", hiddenL: "de"},
en:{startseite: {title: "Home", link: "https://www.bahn.com/en"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/en/contact"}, suchen: "Search", actionLink: "https://www.bahn.com/en/suche", hiddenS: "bahn-com", hiddenL: "en"},
da:{startseite: {title: "Startside", link: "https://www.bahn.com/da"},
 bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Kontakt", link:"https://www.bahn.com/da/kontakt"}, suchen: "Søg", actionLink: "https://www.bahn.com/da/suche", hiddenS: "bahn-com", hiddenL: "da"},
cs:{startseite: {title: "Úvod", link: "https://www.bahn.com/cs"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Hilfe & Kontakt", link:"https://www.bahn.com/cs/kontakt"}, suchen: "najít", actionLink: "https://www.bahn.com/cs/suche", hiddenS: "bahn-com", hiddenL: "cs"},
it:{startseite: {title: "Startseite", link: "https://www.bahn.com/it"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contatti", link:"https://www.bahn.com/it/contatti"}, suchen: "Cerca", actionLink: "https://www.bahn.com/it/suche", hiddenS: "bahn-com", hiddenL: "it"},
fr:{startseite: {title: "Page d´accueil", link: "https://www.bahn.com/fr"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/fr/contact"}, suchen: "Rechercher", actionLink: "https://www.bahn.com/fr/suche", hiddenS: "bahn-com", hiddenL: "fr"},
es:{startseite: {title: "Home", link: "https://www.bahn.com/es"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contacto", link:"https://www.bahn.com/es/contacto"}, suchen: "Buscar", actionLink: "https://www.bahn.com/es/suche", hiddenS: "bahn-com", hiddenL: "es"},
pl:{startseite: {title: "Strona główna", link: "https://www.bahn.com/pl"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Kontakt", link:"https://www.bahn.com/pl/kontakt"}, suchen: "Szukaj", actionLink: "https://www.bahn.com/pl/suche", hiddenS: "bahn-com", hiddenL: "pl"},
nl:{startseite: {title: "Home", link: "https://www.bahn.com/nl"}, 
bahnDe: {title: "", link: ""}, hilfeKontakt: {title:"Contact", link:"https://www.bahn.com/nl/contact"}, suchen: "Zoeken", actionLink: "https://www.bahn.com/nl/suche", hiddenS: "bahn-com", hiddenL: "nl"}}


    const headerDataSetup = ref({
      children: [
        { title: "", link: "", children: [{}], tips: [{title: ""}, {title: ""}]},
        { title: "", link: "", children: [{}], tips: [{title: ""}, {title: ""}]},
        { title: "", link: "", children: [{}], tips: [{title: ""}, {title: ""}]},
      ],
    });

    const menuIndex = ref(0);

    //   const setMenuContentOff = () => {

      const   getMenuData =  () =>  {
      getHttp("https://db-bhfs.db-app.de/menu", {
      screen: "desktop",
      lang: store.state.lang,
    },"").then((res) => {
      headerDataSetup.value = res.data;
      //console.log("getHTTP menu: ");
      //console.log(res);
    });
   }

    const customPopoverOptions = {
      showBackdrop: false,
      cssClass: "language_popover",
    };

   

     getMenuData();

/*
     getHttp("https://db-bhfs.db-app.de/menu", {
      screen: "desktop",
      lang: store.state.lang,
    },"").then((res) => {
      headerDataSetup.value = res.data;
      console.log("getHTTP menu: ");
      console.log(res);
    });
*/


    const valueData = ref(0);
    const headingData1 = ref({});
    const route = useRoute();
    const headingElementOne = ref({
      navDesHeadEl: true,
      navDesHeadElSel: false,
      headingFont: true,
      navDesConHeadEl: true,
      hoverPointer: true,
    });
    const headingElementTwo = ref({
      navDesHeadEl: true,
      navDesHeadElSel: false,
      headingFont: true,
      navDesConHeadEl: true,
      hoverPointer: true,
    });
    const headingElementThree = ref({
      navDesHeadEl: true,
      navDesHeadElSel: false,
      headingFont: true,
      navDesConHeadEl: true,
      hoverPointer: true,
    });
    const headingContent = ref({
      navDesConOff: true,
      navDesConOn: false,
    });

    const langSelectValueCSS = ref({
        langPopOverCustom: false,
        fontDbT5: true,
        languageSelectionButton: true,
    })

    const target = ref(null);

    const headingValue = ref();
    const isOpenRef = ref(false);
    const event = ref();

    const setMenuContentOff = () => {
     
     headingContent.value = {
        navDesConOff: true,
        navDesConOn: false,
      };
      
      headingElementOne.value = {
        navDesHeadEl: true,
        navDesHeadElSel: false,
        headingFont: true,
        navDesConHeadEl: true,
        hoverPointer: true,
      };
      headingElementTwo.value = {
        navDesHeadEl: true,
        navDesHeadElSel: false,
        headingFont: true,
        navDesConHeadEl: true,
        hoverPointer: true,
      };
      headingElementThree.value = {
        navDesHeadEl: true,
        navDesHeadElSel: false,
        headingFont: true,
        navDesConHeadEl: true,
        hoverPointer: true,
      };
    };

   const  setLang = async () => {
     store.commit(MUTATIONS.EDIT_LANG, lang.value);

      getHttp("https://db-bhfs.db-app.de/menu", {
      screen: "desktop",
      lang: store.state.lang,
    },"").then((res) => {
      headerDataSetup.value = res.data;
      //console.log("getHTTP menu: ");
      //console.log(res);
    });
   }

     const  setLangValue = async (langValue: string) => {
       //console.log(langValue);
     store.commit(MUTATIONS.EDIT_LANG, langValue);

      getHttp("https://db-bhfs.db-app.de/menu", {
      screen: "desktop",
      lang: store.state.lang,
    },"").then((res) => {
      headerDataSetup.value = res.data;
      //console.log("getHTTP menu: ");
      //console.log(res);
    });

   
   }

    const setMenuContent = (reiter: number) => {

      if (reiter === 1) {
        
        valueData.value = 0;
        headingData1.value = headerDataSetup.value.children[0];
        menuIndex.value = 0;
        headingElementOne.value = {
          navDesHeadEl: true,
          navDesHeadElSel: true,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementTwo.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementThree.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
      } else if (reiter === 2) {
        valueData.value = 1;
        headingData1.value = headerDataSetup.value.children[1];
        menuIndex.value = 1;
        headingElementOne.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementTwo.value = {
          navDesHeadEl: true,
          navDesHeadElSel: true,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementThree.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
      } else if (reiter === 3) {
        valueData.value = 2;
        headingData1.value = headerDataSetup.value.children[2];
        menuIndex.value = 2;
        headingElementOne.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementTwo.value = {
          navDesHeadEl: true,
          navDesHeadElSel: false,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
        headingElementThree.value = {
          navDesHeadEl: true,
          navDesHeadElSel: true,
          headingFont: true,
          navDesConHeadEl: true,
          hoverPointer: true,
        };
      }
      headingContent.value = {
        navDesConOff: false,
        navDesConOn: true,
      };
    };

    
    const firstLinkTranslation = ref({
      de: "",
      en: "Overview:",
      da: "Oversigt:",
      cs: "Přehled:",
      it: "Panoramica",
      fr: "Vue d’ensemble:",
      es: "Resumen:",
      pl: "Podgląd:",
      nl: "Overzicht:",
    });
  

    const isOpenRefLang = ref(false);
    const eventLang = ref();
    const setOpenLang = (state: boolean, ev?: Event) => {
      if(state){
          langSelectValueCSS.value = {
            langPopOverCustom: true,
          fontDbT5: true,
          languageSelectionButton: true,
          };
      }else{
          langSelectValueCSS.value = {
          langPopOverCustom: false,
          fontDbT5: true,
          languageSelectionButton: true,
          };
      }  

      eventLang.value = ev; 
      isOpenRefLang.value = state;
    }

    return {
      isOpenRef,
      event,
      headingElementOne,
      headingElementTwo,
      headingElementThree,
      headingContent,
      headingValue,
      setMenuContent,
      setMenuContentOff,
      headerDataSetup,
      menuIndex,
      valueData,
      headingData1,
      firstLinkTranslation,
      store,
      setLang,
      lang,
      customPopoverOptions,
      headerTranslation,
      setLangValue,
      langValues,
      isOpenRefLang,
      setOpenLang,
      eventLang,
      langSelectValueCSS,
    };
  },
});
